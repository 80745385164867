<template>
  <div :class="colSize" class="row q-py-sm">
    <div class="col-12 q-px-sm text-h5">Adresgegevens</div>
    <div class="col-4 q-px-sm">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        :outlined="!settings.readonly && !blockPc"
        :filled="settings.readonly || blockPc"
        :disable="settings.readonly || blockPc"
        :modelValue="postcode"
        lazy-rules
        mask="####AA"
        :rules="prospectInformationBlock ? [] : validate('postcode')"
        @change="postcode = $event"
        bottom-slots
        label="Postcode"
      />
    </div>
    <div class="col-4 q-px-sm">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        :outlined="!settings.readonly && !blockPc"
        :filled="settings.readonly || blockPc"
        :disable="settings.readonly || blockPc"
        :modelValue="house_number"
        @change="house_number = $event"
        bottom-slots
        label="Huisnummer"
        lazy-rules
        :rules="prospectInformationBlock ? [] : validate('housenumber')"
      />
    </div>
    <div class="col-4 q-px-sm">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        :outlined="!settings.readonly && !blockPc"
        :filled="settings.readonly || blockPc"
        :disable="settings.readonly || blockPc"
        :modelValue="house_number_addition"
        @change="house_number_addition = $event"
        label="Huisnummer Toevoeging"
      />
    </div>
    <div class="col q-px-sm">
      <q-input
        readonly
        dense
        filled
        :modelValue="street"
        @change="street = $event"
        bottom-slots
        label="Straat"
        :rules="prospectInformationBlock ? [] : validate('required')"
      />
    </div>
    <div class="col q-px-sm">
      <q-input
        readonly
        dense
        filled
        :modelValue="city"
        @change="city = $event"
        bottom-slots
        label="Woonplaats"
        :rules="prospectInformationBlock ? [] : validate('required')"
      />
    </div>
    <div
      v-if="!settings.readonly && !prospectInformationBlock"
      class="col q-px-sm"
    >
      <q-btn
        v-if="settings?.search?.enabled ?? true"
        :disabled="readonly || prospectInformationBlock"
        style="width: 100%"
        class="text-center text-weight-bold"
        no-caps
        label="Zoeken"
        color="primary"
        @click="
          send_message('get_city');
          loading = ['address', true];
        "
        :loading="loading.address ?? false"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useQuasar } from "quasar";
import { useStore } from "vuex";
import { validate, validator } from "../../plugins/validation.js";

//* Props
// eslint-disable-next-line no-undef
defineProps({
  settings: Object,
});

//* VueUse
const $q = useQuasar();
const $store = useStore();

//* Computed
const loading = computed({
  get() {
    return $store.getters.loading ?? false;
  },
  set(newVal) {
    $store.dispatch("setLoading", {
      key: newVal[0],
      value: newVal[1],
    });
  },
});
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);

const prospectInformationBlock = computed(() =>
  $store.getters.view == "retention" ? true : false
);
const colSize = computed(() => ($q.screen.lt.md ? "col-12" : "col-6"));
const blockPc = computed(() => {
  if ($store.getters.projectSpecific["Blockpc"] != "true") {
    return false;
  }

  return true;
});
const postcode = computed({
  get() {
    return $store.getters.record?.Postcode ?? "";
  },
  set(newVal) {
    street.value = "";
    city.value = "";
    $store.dispatch("updateRecord", {
      key: "Postcode",
      value: newVal,
      validated: validator("postcode", newVal),
    });
  },
});

const house_number = computed({
  get() {
    return $store.getters.record?.Huisnummer ?? "";
  },
  set(newVal) {
    street.value = "";
    city.value = "";
    $store.dispatch("updateRecord", {
      key: "Huisnummer",
      value: newVal,
      validated: validator("housenumber", newVal),
    });
  },
});

const house_number_addition = computed({
  get() {
    return $store.getters.record?.HuisnummerToev ?? "";
  },
  set(newVal) {
    street.value = "";
    city.value = "";
    $store.dispatch("updateRecord", {
      key: "HuisnummerToev",
      value: newVal,
    });
  },
});

const street = computed({
  get() {
    return $store.getters.record?.Straat ?? "";
  },
  set(newVal) {
    $store.dispatch("updateRecord", { key: "Straat", value: newVal });
  },
});

const city = computed({
  get() {
    return $store.getters.record?.Woonplaats ?? "";
  },
  set(newVal) {
    $store.dispatch("updateRecord", { key: "Woonplaats", value: newVal });
  },
});

const send_message = (message) => {
  $store.dispatch("send_message", { message });
};
</script>
